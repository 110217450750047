<template>
    <v-app>
        <v-card>
            <v-card-title>
                <router-link class="ml-2 mr-2" :to="{ name:'courses-summary',params:{
                                                          lesson_planner_id:assignment.lesson_planner_id
                                                        }}">
                 <i class="fas fa-arrow-left"></i>   Back
                </router-link> Assignment
            </v-card-title>
            <v-card-text>
                <div class="row">
                    <div class="col-12">
                        <v-text-field outlined dense v-model="assignment.title"
                                      placeholder=" Title"></v-text-field>
                        <span class="text-danger"
                              v-if="$v.assignment.title.$error"> Title is required</span>
                    </div>
                    <div class="col-12">
                        <ckeditor v-model="assignment.description"></ckeditor>
                        <span class="text-danger"
                              v-if="$v.assignment.description.$error">Description is required</span>
                    </div>

                    <div class="col-12">
                        <h4>Attachments</h4>
                        <attachment :assignment_id="assignment_id"></attachment>
                    </div>
                    <div class="col-12">
                        <h4>Marks</h4>
                    </div>
                    <div class="col-12">
                        <div class="row">

                            <div class="col-3">
                                <v-text-field
                                        v-model="assignment.total_marks"
                                        label="Total Marks"
                                        placeholder="Total Marks"
                                        outlined
                                        dense
                                        type="number"
                                ></v-text-field>
                            </div>
<!--                            <div class="col-1">-->
<!--                                <span class="">Pass Marks</span>-->
<!--                            </div>-->
                            <div class="col-3">
                                <v-text-field
                                        v-model="assignment.pass_marks"
                                        label="Pass Marks"
                                        placeholder="Pass Marks"
                                        outlined
                                        dense
                                        type="number"
                                ></v-text-field>
                            </div>
                        </div>

                    </div>

                    <div class="col-12">
                        <h4>Marking Criteria</h4>
                    </div>
                    <div class="col-12">
                        <ckeditor v-model="assignment.assignment_marking_criteria"></ckeditor>
                    </div>
                    <div class="col-12">
                        <h4>Availability</h4>
                    </div>
                    <div class="col-12">
                        <div class="row">
<!--                            <div class="col-2">-->
<!--                                <span class="">Submission Date</span>-->

<!--                            </div>-->
                            <div class="col-2">
                                <v-checkbox
                                        v-model="assignment.enable_submission_date"
                                        label="Enable Submission Date"
                                ></v-checkbox>

                            </div>
                            <div class="col-3 form-group">
                                <datetime  class="p-2 border border-dark"
                                           value-zone="Asia/Kathmandu"
                                           placeholder="Allow Submission Date Time"
                                           :minute-step="15"
                                           format="yyyy-MM-dd hh:mm"
                                           :min-datetime="today"
                                           use12-hour
                                           :disabled="!assignment.enable_submission_date"
                                           type="datetime"
                                           v-model="assignment.allow_submission_date">
                                    <label for="startDate" slot="before"><i class="fas fa-calendar-alt"></i></label>
                                </datetime>
                            </div>
                            <div class="col-3">
                                {{ assignment.allow_submission_date | moment("dddd, MMMM Do YYYY, h:mm a") }}
                            </div>
                        </div>
                        <div class="row">
<!--                            <div class="col-2">-->
<!--                                <span class="">Due date</span>-->

<!--                            </div>-->
                            <div class="col-2">
                                <v-checkbox
                                        v-model="assignment.enable_due_date"
                                        label="Enable Due Date"
                                ></v-checkbox>

                            </div>
                            <div class="col-3 form-group">
                                <datetime  class="p-2 border border-dark"
                                           value-zone="Asia/Kathmandu"
                                           placeholder="Due Date Time"
                                           :min-datetime="assignment.allow_submission_date ? assignment.allow_submission_date : today "
                                           :minute-step="15"
                                           format="yyyy-MM-dd hh:mm"
                                           use12-hour
                                           :disabled="!assignment.enable_submission_date"
                                           type="datetime"
                                           v-model="assignment.due_date">
                                    <label for="startDate" slot="before"><i class="fas fa-calendar-alt"></i></label>
                                </datetime>
                            </div>
                            <div class="col-3">
                                {{ assignment.due_date | moment("dddd, MMMM Do YYYY, h:mm a") }}
                            </div>
                        </div>
                        <div class="row">
<!--                            <div class="col-2">-->
<!--                                <span class="">Cut Off date</span>-->

<!--                            </div>-->
                            <div class="col-2">
                                <v-checkbox
                                        v-model="assignment.enable_cut_off_date"
                                        label="Enable Cut Off date"
                                ></v-checkbox>

                            </div>
                            <div class="col-3 form-group">
                                <datetime  class="p-2 border border-dark"
                                           value-zone="Asia/Kathmandu"
                                           placeholder="Cut Off Date Time"
                                           :minute-step="15"
                                           :min-datetime="today "
                                           format="yyyy-MM-dd hh:mm"
                                           use12-hour
                                           type="datetime"
                                           :disabled="!assignment.enable_cut_off_date"
                                           v-model="assignment.cut_off_date">
                                    <label for="startDate" slot="before"><i class="fas fa-calendar-alt"></i></label>
                                </datetime>
                            </div>
                            <div class="col-3">
                                {{ assignment.cut_off_date | moment("dddd, MMMM Do YYYY, h:mm a") }}
                            </div>
                        </div>
                        <div class="row">
<!--                            <div class="col-2">-->
<!--                                <span class="">Set Reminder</span>-->

<!--                            </div>-->
                            <div class="col-2">
                                <v-checkbox
                                        v-model="assignment.enable_grading_reminder"
                                        label="Set Reminder Date"
                                ></v-checkbox>

                            </div>
                            <div class="col-3 form-group">
                                <datetime  class="p-2 border border-dark"
                                           value-zone="Asia/Kathmandu"
                                           placeholder="Grading Reminder Date Time"
                                           :minute-step="15"
                                           :min-datetime="assignment.allow_submission_date ? assignment.allow_submission_date : today "
                                           format="yyyy-MM-dd hh:mm"
                                           use12-hour
                                           :disabled="!assignment.enable_grading_reminder"
                                           type="datetime"
                                           v-model="assignment.grading_reminder_date">
                                    <label for="startDate" slot="before"><i class="fas fa-calendar-alt"></i></label>
                                </datetime>
                            </div>
                            <div class="col-3">
                                {{ assignment.grading_reminder_date | moment("dddd, MMMM Do YYYY, h:mm a") }}
                            </div>

                        </div>
                    </div>
<!--                    <div class="col-12">-->
<!--                        <h4>Submission types</h4>-->
<!--                    </div>-->
<!--                    <div class="col-12">-->
<!--                        <div class="row">-->
<!--                            <div class="col-2">-->
<!--                                <span class="">Submission types</span>-->

<!--                            </div>-->
<!--                            <div class="col-3">-->
<!--                                <v-select-->
<!--                                        outlined-->
<!--                                        dense-->
<!--                                        v-model="assignment.submission_type"-->
<!--                                        :items="submission_types"-->
<!--                                        item-text="title"-->
<!--                                        item-value="value"-->
<!--                                        label="Submission Type"-->
<!--                                >-->
<!--                                </v-select>-->

<!--                            </div>-->
<!--                            <div class="col-3" v-if="assignment.submission_type ==='file_submission'">-->
<!--                                <v-text-field-->
<!--                                        v-model="assignment.max_no_upload_files"-->
<!--                                        label="Maximum number of uploaded files"-->
<!--                                        placeholder="Maximum number of uploaded files"-->
<!--                                        outlined-->
<!--                                        dense-->
<!--                                        type="number"-->
<!--                                ></v-text-field>-->
<!--                            </div>-->
<!--                            <div class="col-3"  v-if="assignment.submission_type ==='file_submission'">-->
<!--                                <v-text-field-->
<!--                                        v-model="assignment.max_submission_size"-->
<!--                                        label="Maximum submission size"-->
<!--                                        placeholder="Maximum submission size"-->
<!--                                        outlined-->
<!--                                        dense-->
<!--                                        suffix="MB"-->
<!--                                        type="number"-->
<!--                                ></v-text-field>-->
<!--                            </div>-->
<!--                            <div class="col-3"  v-if="assignment.submission_type ==='individual_questions'">-->
<!--                                <v-text-field-->
<!--                                        v-model="assignment.per_page_question"-->
<!--                                        label="Per Page Quesions"-->
<!--                                        placeholder="Per Page Quesions"-->
<!--                                        outlined-->
<!--                                        dense-->
<!--                                        type="number"-->
<!--                                ></v-text-field>-->
<!--                            </div>-->
<!--                            <div class="col-3"  v-if="assignment.submission_type ==='individual_questions'">-->
<!--                                <v-switch-->
<!--                                        v-model="assignment.navigation_allowed"-->
<!--                                        label="Allow Navigation"-->
<!--                                        placeholder="Allow Navigation"-->
<!--                                        outlined-->
<!--                                        dense-->
<!--                                ></v-switch>-->
<!--                            </div>-->

<!--                        </div>-->
<!--                        <div class="row"  v-if="assignment.submission_type ==='file_submission'">-->
<!--                            <div class="col-2">-->
<!--                                <span class="">Accepted Files</span>-->

<!--                            </div>-->
<!--                            <div class="col-9">-->
<!--                                <v-select-->
<!--                                        :items="assignments_accpeted_files"-->
<!--                                        v-model="assignment.accepted_files"-->
<!--                                        label="Accepted Files"-->
<!--                                        item-value="extension"-->
<!--                                        item-text="name"-->
<!--                                        multiple-->
<!--                                        outlined-->
<!--                                        dense-->
<!--                                        persistent-hint-->
<!--                                        small-chips-->
<!--                                >-->

<!--                                </v-select>-->

<!--                            </div>-->

<!--                        </div>-->

<!--                    </div>-->
<!--                    <div class="col-12">-->
<!--                        <h4>Feedback types</h4>-->
<!--                    </div>-->
<!--                    <div class="col-12">-->

<!--                        <div class="row">-->
<!--                            <div class="col-2">-->
<!--                                <span class="">Feedback types</span>-->

<!--                            </div>-->
<!--                            <div class="col-2">-->
<!--                                <v-checkbox-->
<!--                                        v-model="assignment.feedback_types"-->
<!--                                        label="Feedback comments"-->
<!--                                        value="feedback_comments"-->
<!--                                ></v-checkbox>-->
<!--                            </div>-->
<!--                            <div class="col-2">-->
<!--                                <v-checkbox-->
<!--                                        v-model="assignment.feedback_types"-->
<!--                                        label="Annotate PDF"-->
<!--                                        value="annotate_pdf"-->
<!--                                ></v-checkbox>-->
<!--                            </div>-->
<!--                            <div class="col-3">-->
<!--                                <v-checkbox-->
<!--                                        v-model="assignment.feedback_types"-->
<!--                                        label="Offline grading worksheet"-->
<!--                                        value="offline_grading_worksheet"-->
<!--                                ></v-checkbox>-->
<!--                            </div>-->
<!--                            <div class="col-2">-->
<!--                                <v-checkbox-->
<!--                                        v-model="assignment.feedback_types"-->
<!--                                        label="Feedback files"-->
<!--                                        value="feedback_files"-->
<!--                                ></v-checkbox>-->

<!--                            </div>-->

<!--                        </div>-->

<!--                    </div>-->
                    <div class="col-12">
                        <h4>Submission settings</h4>
                    </div>
                    <div class="col-12">

                        <div class="row">
<!--                            <div class="col-2">-->
<!--                                <span class="">Accept Submission Statement</span>-->
<!--                            </div>-->
                          <div class="col-3">
                                <v-checkbox
                                        v-model="assignment.enable_submission_statement"
                                        :label="`${assignment.enable_submission_statement ? 'Ask for Submission Statement' : 'Do not ask for Submission Statement'}`"
                                ></v-checkbox>
                            </div>
<!--                            <div class="col-2">-->
<!--                                <span class="">Allow Re-submission</span>-->
<!--                            </div>-->
                            <div class="col-3">
                                <v-checkbox
                                        v-model="assignment.re_submission"
                                        :label="`${assignment.re_submission ? 'Resubmission Allowed' : 'Resubmission Disabled'}`"
                                ></v-checkbox>

                            </div>
<!--                            <div class="col-2">-->
<!--                                <span class="">Additional attempts</span>-->
<!--                            </div>-->
                            <div class="col-3">
                                <v-checkbox
                                        v-model="assignment.additional_attempt"
                                        :label="`${assignment.additional_attempt ? 'Enable Multiple Submissions' : 'Disable Multiple Submissions'}`"
                                ></v-checkbox>

                            </div>
                            <div class="col-3" v-if="assignment.additional_attempt">
                                <v-text-field
                                        v-model="assignment.max_attempt"
                                        label="No. of Maximum Submission"
                                        placeholder="Maximum Submission"
                                        outlined
                                        dense
                                        type="number"
                                ></v-text-field>
                            </div>


                        </div>

                    </div>
<!--                    <div class="col-12">-->
<!--                        <h4>Group submission settings</h4>-->
<!--                    </div>-->
<!--                    <div class="col-12">-->

<!--                        <div class="row">-->
<!--                            <div class="col-2">-->
<!--                                <span class="">Students submit in groups</span>-->
<!--                            </div>-->
<!--                            <div class="col-1">-->
<!--                                <v-checkbox-->
<!--                                        v-model="assignment.submit_in_groups"-->
<!--                                        :label="`${assignment.submit_in_groups ? 'Yes' : 'No'}`"-->
<!--                                ></v-checkbox>-->
<!--                            </div>-->
<!--                            <div class="col-2">-->
<!--                                <span class="">Require group to make submission</span>-->
<!--                            </div>-->
<!--                            <div class="col-1">-->
<!--                                <v-checkbox-->
<!--                                        v-model="assignment.require_group_submission"-->
<!--                                        :label="`${assignment.require_group_submission ? 'Yes' : 'No'}`"-->
<!--                                ></v-checkbox>-->

<!--                            </div>-->
<!--                        </div>-->

<!--                    </div>-->
<!--                    <div class="col-12">-->
<!--                        <h4>Notifications</h4>-->
<!--                    </div>-->
<!--                    <div class="col-12">-->

<!--                        <div class="row">-->
<!--                            <div class="col-2">-->
<!--                                <span class="">Notify graders about submissions</span>-->
<!--                            </div>-->
<!--                            <div class="col-1">-->
<!--                                <v-checkbox-->
<!--                                        v-model="assignment.notify_submission"-->
<!--                                        :label="`${assignment.notify_submission ? 'Yes' : 'No'}`"-->
<!--                                ></v-checkbox>-->
<!--                            </div>-->
<!--                            <div class="col-2">-->
<!--                                <span class="">Notify graders about late submissions</span>-->
<!--                            </div>-->
<!--                            <div class="col-1">-->
<!--                                <v-checkbox-->
<!--                                        v-model="assignment.notify_late_submission"-->
<!--                                        :label="`${assignment.notify_late_submission ? 'Yes' : 'No'}`"-->
<!--                                ></v-checkbox>-->

<!--                            </div>-->
<!--                            <div class="col-2">-->
<!--                                <span class="">Notify students</span>-->
<!--                            </div>-->
<!--                            <div class="col-1">-->
<!--                                <v-checkbox-->
<!--                                        v-model="assignment.notify_student_submission"-->
<!--                                        :label="`${assignment.notify_student_submission ? 'Yes' : 'No'}`"-->
<!--                                ></v-checkbox>-->

<!--                            </div>-->
<!--                        </div>-->

<!--                    </div>-->

                  <div class="col-12">
                    <h3>Make this assignment visible to students?</h3>
                    <v-switch
                        v-model="assignment.student_visibility"
                        label="Assignment Visibility"
                    >
                    </v-switch>
                  </div>

                    <div class="col-12">
                        <v-switch
                                v-model="assignment.is_active"
                                label="Status"
                        >
                        </v-switch>
                    </div>

                    <div class="col-12 text-right mt-4">
                        <v-btn
                                class="btn btn-standard"
                                depressed
                                @click="resetForm"
                        >Cancel
                        </v-btn>
                        <v-btn
                                class="text-white ml-2 btn btn-primary"
                                depressed
                                @click="createAndUpdate"
                                :loading="isBusy"
                        >Save & Continue
                        </v-btn>
                        <v-btn
                                class="text-white ml-2 btn btn-primary"
                                depressed
                                @click="createAndUpdateReturn"
                                :loading="isBusy"
                        >Save & Return
                        </v-btn>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-app>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import {AssignmentMixin} from "@/mixins/AssignmentMixin";
    import {LessonPlannerMixin} from "@/mixins/LessonPlannerMixin";

    import Attachment from "./Attachment";

    import { Datetime } from 'vue-datetime';
    import 'vue-datetime/dist/vue-datetime.css'
    export default {
        name: "NewAssignment",
        mixins: [AssignmentMixin,LessonPlannerMixin],
        components:{Attachment,datetime: Datetime},
        validations: {
            assignment: {
                title: {required},
                description: {required},
            }
        },
        data() {
            return {
                dialog: false,
                menu: false,
                menu2: false,
                allow_submission_date: false,
                due_date: false,
                cut_off_date: false,
                grading_reminder_date: false,
                isBusy: false,
                edit: false,
                today: new Date().toISOString().substr(0,10),

                types: [
                    {
                        title: 'File Submission',
                        value: 'file_submission'
                    },
                    {
                        title: 'Questions',
                        value: 'questions'
                    },
                    {
                        title: 'Quiz',
                        value: 'quiz'
                    }
                ],
                submission_types: [
                    {
                        title: 'Online Text',
                        value: 'online_text'
                    },
                    {
                        title: 'File Submission',
                        value: 'file_submission'
                    },
                    {
                        title: 'Individual Questions',
                        value: 'individual_questions'
                    },


                ]

            };
        },
        computed: {
            assignment_id() {
                return this.$route.params.assignment_id;
            },

        },
        methods: {

        },
        mounted() {
            this.getSingleAssignment(this.assignment_id);
            this.getAssignmentAcceptedFiles();


        }

    }
</script>

<style scoped>

</style>
